@font-face {
  font-family: walkwayBlack;
  src: url(./assets/Walkway_Black.ttf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /*font-family: walkway;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html::-webkit-scrollbar,
.anglo-hide-scroll-bar::-webkit-scrollbar {
  display: none;
  width: 0px;
}

* {
  user-select: none;
}

input,
textarea {
  -webkit-user-select: text;
  user-select: text;
  -o-user-select: text;
  -ms-user-select: text;
}

audio {
  height: 24px;
}

.flex {
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-1 {
  flex: 1;
}

.angloeta-text-font {
  font-family: walkwayBlack;
  letter-spacing: 1px;
}
.anglo-container {
  max-width: 1360px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}
.angloeta-menu {
  width: 120px;
  color: #fcfcfc;
  cursor: pointer;
  text-align: center;
  font-weight: 800;
  font-size: 17px;
}
.blogMargin {
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 24px;
}
.blogBold {
  font-weight: bold;
}
.red {
  color: rgb(255, 64, 64);
}
.fontSizeSmall {
  font-size: 11px;
}

