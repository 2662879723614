.anglo-block-button > .MuiButton-label {
  flex-direction: column;
}

.anglo-message .MuiSnackbarContent-root,
.anglo-message .MuiSnackbarContent-message {
  padding: 0px;
  width: 100%;
}

.anglo-message .MuiAlert-root {
  padding: 3px 16px 7px;
}

.MuiTab-wrapper {
  text-transform: none;
  font-size: 14px;
  font-weight: bold;
}

.anglo-tabs .MuiTab-wrapper {
  /*align-items: flex-start;*/
  display: inline;
  text-align: left;
  color: #333333;
  font-size: 16px;
}
/*.anglo-tabs .Mui-selected {
  color: #f0f0f0;
  background-color: #ff4040;
}*/

.MuiInputAdornment-root.MuiInputAdornment-positionStart:focus,
.anticon.anticon-close:focus {
  outline: 0;
}

.anglo-card .MuiChip-root {
  height: 24px;
  justify-content: flex-start;
}

.anglo-first-letter-capitalize:first-letter{
  text-transform: capitalize;
}

.MuiTab-textColorInherit.Mui-selected {
  background-color: #ff4040;
  /*background-color: #999999;*/
}
.MuiTab-textColorInherit.Mui-selected > .MuiTab-wrapper {
  color: #f0f0f0;
}

.angloeta-switch.MuiSwitch-root {
  padding: 9px;
}

.angloeta-switch .MuiSwitch-track {
  border-radius: 10px;
}

.angloeta-switch .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #ff4040;
}

.angloeta-switch .MuiSwitch-colorSecondary.Mui-checked {
  color: #dc0000;
}